export const environment = {
  production: false,
  serverUrl: "https://admin.nhaphangorder.vn/api",
  defaultLanguage: "vi-VN",
  supportedLanguages: ["en-US", "vi-VN"],
  firebaseBo: {
    apiKey: "AIzaSyAXqXYlnRjgC1R8yVG-HBPMuTMyjr1Weuo",
    authDomain: "nhaphangorder-45c36.firebaseapp.com",
    databaseURL: "https://nhaphangorder-45c36-default-rtdb.firebaseio.com",
    projectId: "nhaphangorder-45c36",
    storageBucket: "nhaphangorder-45c36.appspot.com",
    messagingSenderId: "464079873077",
    appId: "1:464079873077:web:2a5e397e0d522cca15fd7c",
    measurementId: "G-YDGGP716M6",
  },
  firebaseIdms: {
    apiKey: "AIzaSyAXqXYlnRjgC1R8yVG-HBPMuTMyjr1Weuo",
    authDomain: "nhaphangorder-45c36.firebaseapp.com",
    databaseURL: "https://nhaphangorder-45c36-default-rtdb.firebaseio.com",
    projectId: "nhaphangorder-45c36",
    storageBucket: "nhaphangorder-45c36.appspot.com",
    messagingSenderId: "464079873077",
    appId: "1:464079873077:web:2a5e397e0d522cca15fd7c",
    measurementId: "G-YDGGP716M6",
  },
  branchUid: "UhQDbDi42iR7UFdIVpRt",
  emailPrefix: "nhtb_",
  tenantId: "socorder-3yi93"
};

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { UserProfile } from 'src/app/layout/user-profile/user-profile.model';
import { FIREBASE_STRUCT, firebaseIdms } from 'src/app/app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Handling } from 'src/app/decorators/handling';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
})
export class GuideComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  contentHtml = '';
  modalTitle = '';
  contentNode = '';
  qrs = [];
  transferSyntax = '';

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    private navParams: NavParams,
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    private apiService: ApiServiceService
  ) {
    this.modalTitle = this.navParams.get('modalTitle');
    this.contentNode = this.navParams.get('contentNode');
  }

  @Handling()
  async ngOnInit() {
    const contentNode: string = this.navParams.get('contentNode');
    const userUid = this.firebaseIdms.auth.currentUser.uid;
    const user: any = await this._getCurrentUser(userUid);
    this.transferSyntax = `SCK ${user.login_name}`;
    if (contentNode === 'home_recharge_guide') {
      await this._generateQR(user);
    }
    
    this.getContent(contentNode).subscribe((content: any) => {
      if (content) {
        this.contentHtml = content.content_html;
      } else {
        this.contentHtml = '';
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async onCopyText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  getContent(contentNode: string) {
    return this.fs.collection('PARAMETER_CONTENT').doc(`${contentNode}`).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  private async _getCurrentUser(userUid: string) {
    return this.fs.collection<any>(FIREBASE_STRUCT.USERS.NODE).doc<UserProfile>(userUid).snapshotChanges().pipe(
      map(doc => ({ uid: userUid, ...doc.payload.data() })),
      first()
    ).toPromise();
  }

  private async _generateQR(user) {
    const addInfo = this.transferSyntax;
    const bankAccounts = [
      { bankName: 'ACB', acqId: '', accountNo: '16631331', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FnPFQ5abaETryrkrgVkKp?alt=media&token=3d6a2a18-49c7-41bd-8bb1-58c1570914c0', note: 'Phòng giao dịch Kim Đồng, Hà Nội' },
      { bankName: 'HDBANK', acqId: '', accountNo: '097704070007591', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FtwVNMkNQLroNIJsu0HIQ?alt=media&token=a4944bd4-c8c9-4b5f-8093-96c4ed7d9688', note: 'Chi nhánh Hà Nội' },
      { bankName: 'MSB', acqId: '', accountNo: '03501016875761', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FciewtQO1DQmdZfPRDWyi?alt=media&token=38013c1d-8a80-446e-89bc-f4fcd96fd67b', note: 'Chi nhánh Hà Nội' },
      { bankName: 'PVCOMBANK', acqId: '', accountNo: '104002374919', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2Fg0v9ohGNYINspwA4lNOU?alt=media&token=1e20f58c-bf8b-401d-8862-bf65623610c8', note: 'Chi nhánh Hà Nội' },
      { bankName: 'SHB', acqId: '', accountNo: '0908322166', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2Fcq9sJICxZOEfNh2MMby6?alt=media&token=b6dbd259-9dfb-471c-86f4-14b71108f42b', note: 'Chi nhánh Hà Nội' },
      { bankName: 'TECHCOMBANK', acqId: '', accountNo: '19032078428996', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FCrCxT1pY3pBsahxXZV7T?alt=media&token=b79cb313-6b01-4273-af76-a428dd1f73c7', note: 'Chi nhánh Hà Nội' },
      { bankName: 'VIB', acqId: '', accountNo: '908322166', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FuqTzzNnroIQLPnxQ0Ikh?alt=media&token=92d2bba8-6e8d-4a2a-adf6-02b5fd838481', note: 'Chi nhánh Hà Nội' },
      { bankName: 'VPBANK', acqId: '', accountNo: '0908322166', accountName: 'NGUYEN TUAN MINH', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2F4fK06gsyJOmqsgW2qa4o?alt=media&token=ed7c1466-1041-43da-9dee-22df51f1f477', note: 'Chi nhánh Hà Nội' }
    ];

    /* const httpOptions = {
      headers: new HttpHeaders({
        xClientId: '7f087dee-3f77-4d72-a987-d343c6d6edff',
        xApiKey: 'e9358faf-d8e8-4cfc-86be-49440c4c80b8'
      })
    }; */
    for (const bankAccount of bankAccounts) {
      /* const result: any = await this.apiService.postExternalURL('https://api.vietqr.io/v2/generate', { 
        accountNo: bankAccount.accountNo,
        accountName: bankAccount.accountName,
        acqId: bankAccount.acqId,
        addInfo: bankAccount.addInfo,
        format: bankAccount.format,
        template: bankAccount.template,
      }, httpOptions);

      this.qrs.push({ ...result, ...bankAccount }); */

      this.qrs.push({ ...bankAccount });
    }
  }

}
